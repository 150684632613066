import React, { useEffect, useState } from "react";

import './Card.css';


const Card=({data})=>{

    return (
        data && <div className="card p-6 shadow-lg flex justify-center items-center h-[370px]">

            
<div className="bg-primary rounded-full flex items-center justify-center h-[60px] w-[60px]">
          <img src={data.icon} className="h-[40px] w-[40px]"/>
          </div>
            <div className="w-full h-[70px]">
        <p className="card-title mt-2 mb-4">{data.heading}</p>
            </div>
        <div className="w-full h-[200px] overflow-y-scroll">
        <p className="small-desc">
          {data.para}
        </p>
        </div>
        
        <div className="go-corner">
          <div className="go-arrow"></div>
        </div>
      </div>
    )
}

export default Card