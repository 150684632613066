import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions=props=>{

    const data=[
        {
            heading:`Introduction:`,
            para:`Welcome to Tawheed Travel UK. These Terms and Conditions govern your use of our website and services. By accessing or using our services, you agree to be bound by these Terms and Conditions.`
        },
        {
            heading:`User Conduct and Obligations:`,
            para:`You agree to use our services for lawful purposes and in a manner consistent with these Terms and Conditions. You agree not to engage in any activity that could damage or disrupt our services, or interfere with the use of our services by others. You also agree to provide accurate and complete information when using our services.`
        },
        {
            heading:`Intellectual Property:`,
            para:`All content and materials on our website and services, including text, graphics, logos, images, and software, are the property of Tawheed Travel UK or its licensors and are protected by United States and international copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, or distribute any of our content or materials without our prior written consent.`
        },
        {
            heading:`Payment and Refunds:`,
            para:`Our payment terms and options are listed on our website. We reserve the right to change our payment terms and options at any time. If you cancel a trip, our refund policy will apply. Refunds will be processed according to our refund policy and will be credited back to the original payment method used. If any Taxes or Services charges applied then those will be charged.`
        },
        {
            heading:`Disclaimer and Limitation of Liability:`,
            para:`Our services are provided on an “as is” and “as available” basis. We do not make any representations or warranties, express or implied, about the completeness, accuracy, reliability, suitability, or availability of our services. We shall not be liable for any damages, including but not limited to direct, indirect, incidental, consequential, or punitive damages, arising out of or in connection with the use or inability to use our services.`
        },
        {
            heading:`Termination and Suspension:`,
            para:`We reserve the right to terminate or suspend your account or access to our services at any time and for any reason, without notice or liability. Upon termination, all provisions of these Terms and Conditions which by their nature should survive, including but not limited to intellectual property provisions, payment and refund provisions, and limitations of liability, shall survive.`
        },
        {
            heading:`Changes to the Terms and Conditions:`,
            para:`We reserve the right to modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on our website. Your continued use of our services after we post any modifications to the Terms and Conditions will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms and Conditions.`
        },
        {
            heading:`Governing Law and Dispute Resolution:`,
            para:`These Terms and Conditions and your use of our services shall be governed by and construed in accordance with the laws of the State of New Jersey without giving effect to any principles of conflicts of law. Any disputes arising out of or in connection with these Terms and Conditions or your use of our services shall be resolved exclusively by arbitration in accordance with the rules of the American Arbitration Association.`
        },
        {
            heading:`Miscellaneous:`,
            para:`These Terms and Conditions constitute the entire agreement between you and Tawheed Travel UK regarding the use of our services. If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Failure to enforce any right or provision of these Terms and Conditions shall not be deemed a waiver of such right or provision. Any notices or communications regarding these Terms. and Conditions`
        },
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-10">
                         <p className=' text-2xl tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        <p className=' text-md mb-3 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	
</div>
    )
}
export default TermandConditions