import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import TopSection from '../../components/TermandConditions/TopSection';
import ContactForm from '../../components/TermandConditions/TermAndCondtions';

import CustomizeSection from '../../components/Home3/CustomizeSection';



const TandCIndex=props=>{

    return(
        <div>
            <Navbar bg={1}/>
            <div className='max-md:p-3'>
            <TopSection/>
            <ContactForm/>
            <CustomizeSection/>
            </div>
            <Footer/>
        </div>
    )
}
export default TandCIndex