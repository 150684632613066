import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import '../Home3/swiper.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import { EffectFade, Navigation, Pagination,Autoplay,EffectCoverflow,EffectCreative } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from "../Button/Button";
import { Circle, Facebook, Linkedin, Phone, Twitter } from "react-feather";
import Second from '../../assets/images/home/thirdSection/2.jpg';
import Banner from '../../assets/images/blog/user-img-1.jpg';
import tables from '../../assets/images/blog/tables.jpg';
import img3 from '../../assets/images/blog/blog-titleimg.jpg';
import ImageReactangle from '../Contact/ImageReactangle';
import Image from '../Contact/Image';
import Footer from "../footer";
import Logo from "../../assets/images/logo.png";
import B1 from "../../assets/images/banner/b1.jpg";
import B2 from "../../assets/images/banner/b2.jpg";
import B3 from "../../assets/images/banner/b5.webp";
import B4 from "../../assets/images/banner/b6.webp";
import planDep from "../../assets/images/banner/planeDep.svg";
import planAri from "../../assets/images/banner/planeAri.svg";
import HeadingSep from "../../assets/images/banner/heading-sep.png";
import Bed from "../../assets/images/Detail/bed-svgrepo-com.png";
import Plane from "../../assets/images/Detail/plane-svgrepo-com.png";

import service from "../../Service/Service";
import Navbar from "../navbar";
import CustomizeSection from "../Home3/CustomizeSection";
import InputSection from "../Home3/InputSection";

const PackageDetail=props=>{
  const [hotels,setHotels]=useState([])
  const [makkahhotels,setMakkahHotels]=useState()
  const [madinahhotels,setMadinahHotels]=useState()
  const [imagesAll,setImagesAll]=useState([])
  const [imagesMak,setImagesMak]=useState([])
  const [imagesMad,setImagesMad]=useState([])

  const [rating,setRating]=useState(3)

  const [packages,setPackage]=useState()
  
const getHotels=()=>{
service.getAllhotel().then(response=>{
    let h=response.data;
    let makkah=h.find(x=>x.uid==packages.makkah_hotel);
    
    let madinah=h.find(x=>x.uid==packages.madina_hotel);
    let allImg=[];
    let makImg=[];
    let madImg=[];
    allImg.push(makkah.img1)
    allImg.push(makkah.img2)
    allImg.push(makkah.img3)
    allImg.push(makkah.img4)

    makImg.push(makkah.img1)
    makImg.push(makkah.img2)
    makImg.push(makkah.img3)
    makImg.push(makkah.img4)


    allImg.push(madinah.img1)
    allImg.push(madinah.img2)
    allImg.push(madinah.img3)
    allImg.push(madinah.img4)

    madImg.push(madinah.img1)
    madImg.push(madinah.img2)
    madImg.push(madinah.img3)
    madImg.push(madinah.img4)

    setMakkahHotels(makkah);
    setMadinahHotels(madinah);
  setHotels(response.data)

  setImagesAll(allImg);
  setImagesMak(makImg);
  setImagesMad(madImg);
})
}

useEffect(()=>{
if(packages && packages.uid){
    getHotels();
}
},[packages])

const getPackages=()=>{
  const search = window.location.search;
const params = new URLSearchParams(search);

  let packId=params.get('package');
  service.getpackagebyid({uid:packId}).then(response=>{
    setPackage(response.data[0])
    if(response.data[0].category_name.includes('4')){
      setRating(4);
    }
    else if(response.data[0].category_name.includes('5')){
      setRating(5);
    }
  })
  }

    useEffect(()=>{
      getPackages();
        AOS.init();
        
    },[])

    return(<div>
<Navbar bg={1}/>
<div className="mt-16">
    <div className="grid grid-cols-1 md:grid-cols-2  mt-10 p-3 md:p-10 ">
      {
        packages && imagesAll && imagesAll.length>0 && 
        <BackSwiper packages={packages} imagesAll={imagesAll}/>
        
      }
      {
        packages && imagesAll && imagesAll.length>0 && 
        <DetailSection packages={packages} rating={rating}/>
      }
        
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2  p-10 bg-primary">
    <p className='col-span-full tracking-[1px] text-3xl font-[700] leading-12 my-3 px-1 py-4 text-center'>Accomodation Features</p>
      {
        makkahhotels && imagesMak && imagesMak.length>0 && 
        <DescriptionSection hotel={makkahhotels} rating={rating} text={`Hotel in Makkah`}/>
      }
      {
        makkahhotels && imagesMak && imagesMak.length>0 && 
        <BackSwiper packages={packages} imagesAll={imagesMak}/>
        
      }
        
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2  p-10">
    {
        madinahhotels && imagesMad && imagesMad.length>0 && 
        <BackSwiper packages={packages} imagesAll={imagesMad}/>
        
      }
      {
        madinahhotels && imagesMad && imagesMad.length>0 && 
        <DescriptionSection hotel={madinahhotels} rating={rating} text={`Hotel in Madinah`}/>
      }
      
        
    </div>

    <CustomizeSection/>
    <Footer/>
</div>
</div>
    )
}


const BackSwiper=({imagesAll})=>{
    return(
        <div className="w-full h-[50vh] md:h-[70vh] 2xl:h-[50vh] p-1 md:p-4 ">
<Swiper
        spaceBetween={30}
        navigation={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {
            imagesAll.map(x=>{
                return         <SwiperSlide>
                <img src={`https://usapis.umrahsales.co.uk/uploads/${x}`} className="object-cover"/>
              </SwiperSlide>
            })
        }

      </Swiper>
            
        </div>
    )
}

const DetailSection=({packages,rating})=>{
  const [showtoast,setShowToast] = useState(false);
    return(
        <div className="w-full p-4">
            <p className='tracking-[2px] text-4xl font-[700] leading-6 mt-1 px-1 py-2 '>{packages.name}</p>
            <p className='tracking-[1px] text-md mb-2  leading-6 mt-1 px-1 py-2 '>Perform Umrah with Best Price</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-2 bg-primary">
                <p className='tracking-[1px] text-md font-[500]  leading-6 px-1 py-1 '>Makkah Hotel Nights ({packages.makkah_nights})</p>
                <p className='tracking-[1px] text-xl font-[500]  leading-7 px-1  '>{packages.makkah_hotel_name}</p>
                </div>
                <div className="p-2 bg-primary">
                <p className='tracking-[1px] text-md font-[500]  leading-6 px-1 py-1 '>Madinah Hotel Nights ({packages.madina_nights})</p>
                <p className='tracking-[1px] text-xl font-[500]  leading-7 px-1  '>{packages.madinah_hotel_name}</p>
                </div>
            </div>
<hr className="mt-3"/>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-1">
              <div className="flex p-2 items-center ">
                <img src={Plane} className="h-[50px] w-[50px] rounded-full bg-secondary p-2 flex justify-center "/>
                <p className='tracking-[1px] text-lg font-[400]  leading-7 px-1  '>Flight</p>
              </div>

              <div className="flex p-2 items-center ">
                <img src={Bed} className="h-[50px] w-[50px] rounded-full bg-secondary p-2 flex justify-center "/>
                <p className='tracking-[1px] text-lg font-[400]  leading-7 px-1  '>Makkah Hotel</p>
              </div>

              <div className="flex p-2 items-center ">
                <img src={Bed} className="h-[50px] w-[50px] rounded-full bg-secondary p-2 flex justify-center "/>
                <p className='tracking-[1px] text-lg font-[400]  leading-7 px-1  '>Madinah Hotel</p>
              </div>
                
            </div>

            <hr/>



    <div className="rating flex justify-start p-1 my-2">
  <input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked/>
  <input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked />
  <input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked />
  <input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked={rating==4 || rating==5}/>
  <input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked={rating==5}/>
</div>
<p className='tracking-[2px] text-4xl font-[800] leading-6 mt-1 px-1 py-2 '>Price: ${packages.price}</p>
<div className="flex my-2">
<button className="btn border-secondary bg-[transparent]  self-center mx-2 hover:bg-secondary hover:text-white"><Phone className=""/> 124-442-2887</button>
<button className="btn border-secondary bg-[transparent]  self-center hover:bg-secondary hover:text-white" onClick={()=>document.getElementById('my_modal_3').showModal()}><Circle/> Book Now</button>
</div>
<dialog id="my_modal_3" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white">✕</button>
    </form>
    <InputSection setShowToast={setShowToast}/>
  </div>
</dialog>

        </div>
    )
}

const DescriptionSection=({hotel,rating,text})=>{
  console.log(hotel);
  return(
      <div className="w-full p-4">
          <p className='tracking-[2px] text-xl md:text-4xl font-[700] leading-6 mt-1 px-1 py-2 '>{hotel.name}</p>
          <p className='tracking-[2px] text-lg md:text-lg font-[400] leading-6 mt-1 px-1 py-2 '>{text}</p>

  <div className="rating flex justify-start p-1 my-2">
<input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked/>
<input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked />
<input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked />
<input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked={rating==4 || rating==5}/>
<input type="radio" name={`rating-11`} className="mask mask-star-2 bg-secondary" checked={rating==5}/>
</div>

<p className='tracking-[1px] text-lg font-[400] leading-6 mt-1 px-1 py-2 '>{hotel.description}</p>
<p className='tracking-[1px] text-lg font-[400] leading-6 mt-1 px-1 py-2 '>{hotel.description2}</p>

      </div>
  )
}




export default PackageDetail