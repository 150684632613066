import App from "../App";
import { appConfig } from "../appConfig";



import Contact from "../views/Contact";

import Home3 from "../components/Home3/Topsection";

import PackageDetail from "../components/Packagedetail/Packagedetail";
import Aboutus from "../views/Aboutus";
import CookiePolicy from "../views/CookiePolicy/index";
import PaymentSecurity from "../views/PaymentSecurity/index";
import PrivacyPolicy from "../views/PrivacyPolicy/index";
import TC from "../views/TermsAndCondition/index";
import UmrahVisa from "../views/UmrahVisa/index";
import AllPackages from "../components/AllPackages/AllPackages";


const headRoute = appConfig.headRoute

export const routes=[

    {
        path:`${headRoute}/`,
        element:<Home3/>,
    },
    {
        path:`${headRoute}/contact`,
        element:<Contact/>,
    },
    {
        path:`${headRoute}/aboutus`,
        element:<Aboutus/>,
    },
    {
        path:`${headRoute}/home3`,
        element:<Home3/>,
    },
    {
        path:`${headRoute}/packagedetail`,
        element:<PackageDetail/>,
    },
    {
        path:`${headRoute}/cookiepolicy`,
        element:<CookiePolicy/>,
    },
    {
        path:`${headRoute}/privacypolicy`,
        element:<PrivacyPolicy/>,
    },
    {
        path:`${headRoute}/paymentsecurity`,
        element:<PaymentSecurity/>,
    },
    {
        path:`${headRoute}/tc`,
        element:<TC/>,
    },
    {
        path:`${headRoute}/umrahvisa`,
        element:<UmrahVisa/>,
    },
    {
        path:`${headRoute}/allpackages`,
        element:<AllPackages/>,
    },
    
]