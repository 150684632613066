import React, { useEffect, useState } from "react";
import service from "../../Service/Service";
import MyInput from "../Input/MyInput";
import { ThumbsUp } from "react-feather";

const InputSection=({setShowToast})=>{
    const [departure,setDeparture]=useState('');
    const [arrival,setArrival]=useState('');
    const [date,setDate]=useState('');
    const [name,setName]=useState('');
    const [phone,setPhone]=useState('');
    const [email,setEmail]=useState('');
    const [accomodation,setAccomodation]=useState('');
    const [makkah_nights,setMakkahNights]=useState(1);
    const [madinah_nights,setMadinahNights]=useState(1);
    const [rooms,setRooms]=useState('');
    const [adults,setAdults]=useState(1);
    const [children,setChildren]=useState(0);
    const [infants,setInfants]=useState(0);
    const [description,setDescription]=useState('');
    const [travelerSelect,setTravlerSelect]=useState(false);

    const [categories,setCategories]=useState([])
    const [packages,setPackages]=useState([])
    const [airportDep,setAirportDep]=useState([])
    const [airportArv,setAirportArv]=useState([])
    const [toast,showToast]=useState(false)
    const [error,setError]=useState('')
console.log(description);
  
    const getAllAirports=()=>{
    service.getAllAirports().then(response=>{
      let dep= response.data.filter(x=>x.type=='DEPARTURE')
      let arv= response.data.filter(x=>x.type=='ARRIVAL')
      setAirportDep(dep);
      setAirportArv(arv)
    })
    }
  
      useEffect(()=>{
          
          getAllAirports();
      },[])

    const submit=()=>{
      if(!departure){
        setError('Please Select Departure');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!arrival){
        setError('Please Select Arrival');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!date){
        setError('Please Select Date');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!name){
        setError('Please Enter Full Name');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!phone){
        setError('Please Enter Phone');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!email){
        setError('Please Enter Email');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!accomodation){
        setError('Please Select Accomodation');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!makkah_nights){
        setError('Please Select Makkah Nights');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!madinah_nights){
        setError('Please Select Madinah Nights');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!rooms){
        setError('Please Select Rooms');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      

        let params={
            departure,
            arrival,
            date,
            full_name:name,
            phone,
            email,
            accomodation,
            madina_nights:madinah_nights,
            makkah_nights,
            rooms,
            adult:adults,
            children,
            infants,
            status:'NEW',
            reviews:'',
            description:description
        }

        service.addBooking(params).then(x=>{
          document.getElementById('my_modal_4').showModal()
          if(setShowToast){
            setShowToast(true);
          }
        }).catch(err=>{
          console.log(err);
        })
    }

    return(
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
      {
          toast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info bg-[red]">
      <span className="text-white text-lg">{error}</span>
    </div>
          </div>
  
    
  </div>
        }
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Departure Airport</label>
          <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setDeparture(e.target.value)}}>
  
    <option disabled selected>Pick your Departure</option>
    {
      airportDep.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
    
  </select>
        </div>
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white"> Arrival Airport</label>
          <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setArrival(e.target.value)}}>
    <option disabled selected>Pick your Arrival</option>
    {
      airportArv.map(x=>{
        return <option value={x.uid}>{x.name}</option>
      })
    }
  </select>
        </div>
        <div className="p-1 flex flex-col">
          <label className="tracking-[1px] font-[500] leading-6 text-white">Date</label>
          <input type="date" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" onChange={(e)=>{setDate(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white">Full Name</label>
        <input type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white">Phone Number</label>
        <input type="Number" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" onChange={(e)=>{setPhone(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white">Email</label>
        <input type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white"> Accomodation</label>
        <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setAccomodation(e.target.value)}}>
    <option disabled selected>Pick your favorite language</option>
    <option value={`3 STAR`}>3 START</option>
    <option value={`4 STAR`}>4 START</option>
    <option value={`5 STAR`}>5 START</option>
  </select>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white"> Nights in Makkah</label>
        <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setMakkahNights(e.target.value)}}>
    <option disabled selected>Pick No of Nights in Makkah</option>
    <option value={1}>1</option>
    <option value={2}>2</option>
    <option value={3}>3</option>
    <option value={4}>4</option>
    <option value={5}>5</option>
    <option value={6}>6</option>
    <option value={7}>7</option>
    <option value={8}>8</option>
    <option value={9}>9</option>
    <option value={10}>10</option>
  </select>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white"> Nights in Madinah</label>
        <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setMadinahNights(e.target.value)}}>
        <option disabled selected>Pick No of Nights in Madinah</option>
    <option value={1}>1</option>
    <option value={2}>2</option>
    <option value={3}>3</option>
    <option value={4}>4</option>
    <option value={5}>5</option>
    <option value={6}>6</option>
    <option value={7}>7</option>
    <option value={8}>8</option>
    <option value={9}>9</option>
    <option value={10}>10</option>
  </select>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white"> Rooms</label>
        <select className="select select-secondary w-full max-w-xs" onChange={(e)=>{setRooms(e.target.value)}}>
        <option disabled selected>Rooms</option>
    <option value={'single'}>Single</option>
    <option value={'double'}>Double</option>
    <option value={'triple'}>Triple</option>
    <option value={'quad'}>Quad</option>
    
  </select>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 text-white"> Travlers</label>
        <div>
        <div className="dropdown dropdown-end bg-white w-full rounded-lg">
          <div className="h-full w-full p-1 btn flex" tabIndex={0}>
            <p className="text-sm">{travelerSelect?`${adults}A,${children}C,${infants}I`:`Travellers`}</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>
    
    <div tabIndex={0} className="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box w-[220px]">
      <div className="card-body">
        <div>
          <p className="my-1 text-black">Adults (12+)</p>
        <div className="flex border-solid border-2 border-secondary rounded-lg">
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setAdults(adults+1)}}>+</button>
          <input type="number" className="w-1/2 bg-primary text-center" value={adults} onChange={(e)=>{setAdults(e.target.value)}}/>
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setAdults(adults-1)}}>-</button>
        </div>
  
        <p className="my-1 text-black">Children</p>
        <div className="flex border-solid border-2 border-secondary rounded-lg">
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setChildren(children+1)}}>+</button>
          <input type="number" className="w-1/2 bg-primary text-center" value={children} onChange={(e)=>{setChildren(e.target.value)}}/>
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setChildren(children-1)}}>-</button>
        </div>
  
        <p className="my-1 text-black">Infants</p>
        <div className="flex border-solid border-2 border-secondary rounded-lg">
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setInfants(infants+1)}}>+</button>
          <input type="number" className="w-1/2 bg-primary text-center" value={infants} onChange={(e)=>{setInfants(e.target.value)}}/>
          <button className="btn bg-secondary text-white hover:bg-black text-lg" onClick={(e)=>{setInfants(infants-1)}}>-</button>
        </div>

        <p className="my-1 text-black">Message</p>
        <div className="flex border-solid border-2 border-secondary rounded-lg">
        <MyInput
                            placeholder='Message'
                            type='textarea'
                            inputClass='h-[80px]'
                            onChange={(e)=>setDescription(e.target.value)}
                            />
        </div>
  
        <button className="btn bg-secondary text-white hover:bg-black text-md w-full" onClick={(e)=>{setTravlerSelect(true)}}>Select</button>
  
        </div>
        
      </div>
    </div>
  </div>
        </div>
  
        </div>
        
        <div className="p-1">
        <button className="btn rounded-xl bg-primary w-full my-5" onClick={submit}>Submit</button>
        </div>
        <dialog id="my_modal_4" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={()=>{window.location.reload()}}>✕</button>
    </form>
    <p className="text-xl text-white p-8"><ThumbsUp/> Booking Submitted</p>
  </div>
</dialog>
      </div>
    )
  }


  
export default InputSection