import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import TopSection from '../../components/AboutUs/TopSection';

import CustomizeSection from '../../components/Home3/CustomizeSection';
import AboutusDetail from '../../components/AboutUs/AboutusDetail';



const Aboutus=props=>{

    return(
        <div>
            <Navbar bg={1}/>
            <div className='max-md:p-3'>
            <TopSection/>
            <AboutusDetail/>
            <CustomizeSection/>
            </div>
            <Footer/>
        </div>
    )
}
export default Aboutus