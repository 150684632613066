import  axios  from "axios"

const baseURL=`https://usapis.umrahsales.co.uk`
// POST
const postExecutionHOF = async (api, data,id) => {
    
    // let URL = "http://localhost:3002"
    if(id){ return axios.post(`${baseURL}${api}?id=${id}`, data)}
    
    return {data:[]}
}
// GET
// Get Manage HOF
const getExecutionHOF = async (api) => {
    
    // let URL = "http://localhost:3002"
    return {data:[]}
}




const uploadImage = (data)=>postExecutionHOF('/upload_file',data)
const login = (data)=>postExecutionHOF('/auth/login',data)
const deleteBlog = (data)=>postExecutionHOF('/delete-blog',data)
const getCategories = (data)=>getExecutionHOF('/get-categories')
const addBlog = (data)=>postExecutionHOF('/add-blog',data)
const getAllBlogs = (data)=>getExecutionHOF('/get-blogs')
const addEvent = (data)=>postExecutionHOF('/add-event',data)
const getAllEvents = (data)=>getExecutionHOF('/get-events')
const deleteEvent = (data)=>postExecutionHOF('/delete-event',data)


const addAirport = (data)=>postExecutionHOF('/add-airport',data)
const deleteAirport = (data)=>postExecutionHOF('/delete-airport',data)
const getAllAirports = (data)=>getExecutionHOF('/get-airports')
const addCategory = (data)=>postExecutionHOF('/add-category',data)
const deleteCategory = (data)=>postExecutionHOF('/delete-category',data)
const getAllCategory = (data)=>getExecutionHOF('/get-all-category')
const addHotel = (data)=>postExecutionHOF('/add-hotel',data)
const deletehotel = (data)=>postExecutionHOF('/delete-hotel',data)
const getAllhotel = (data)=>getExecutionHOF('/get-all-hotel')
const addPackage = (data)=>postExecutionHOF('/add-package',data)
const deletepackage = (data)=>postExecutionHOF('/delete-package',data)
const getAllpackage = (data)=>getExecutionHOF('/get-all-package')
const getAllpackageByCatId = (data)=>getExecutionHOF('/get-all-package-by-catid?category_id='+data.category_id)
const getpackagebyid = (data)=>getExecutionHOF('/get-package-by-id?'+`uid=${data.uid}`)
const addBooking = (data)=>postExecutionHOF('/add-booking',data)
const addContact = (data)=>postExecutionHOF('/add-contact',data)



export default { 
   addCategory,
   uploadImage,
   login,
   getCategories,
   addBlog,
   getAllBlogs,
   deleteBlog,
   addEvent,
   getAllEvents,
   deleteEvent,
   addAirport,
   getAllAirports,
   deleteAirport,
   deleteCategory,
   getAllCategory,
   addHotel,
   deletehotel,
   getAllhotel,
   addPackage,
   deletepackage,
   getAllpackage,
   getpackagebyid,
   addBooking,
   addContact,
   getAllpackageByCatId
}