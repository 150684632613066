import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions=props=>{

    const data=[
        {
            heading:`Introduction:`,
            para:`Haq Travel UK is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, store, and protect personal information collected from our users. By accessing or using our services, you agree to the terms of this Privacy Policy.`
        },
        {
            heading:`Personal Information Collected:`,
            para:`We may collect personal information from you, such as your name, email address, phone number, and billing information, when you use our services. We collect this information when you book a trip, create an account, or subscribe to our newsletter.`
        },
        {
            heading:`Use of Personal Information:`,
            para:`We use your personal information to provide you with our services, such as processing your bookings and communicating with you about your trip. We may also use your information to personalize your experience, improve our services, and send you promotional emails. We may share your information with third-party service providers who assist us in providing our services.`
        },
        {
            heading:`Data Retention and Security:`,
            para:`We will retain your personal information for as long as necessary to provide you with our services and as required by applicable laws. We take reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction.`
        },
        {
            heading:`Cookies and Tracking Technologies:`,
            para:`We use cookies and tracking technologies to personalize your experience, analyze site usage, and improve our services. Cookies are small data files that are placed on your device when you visit our website. You can disable cookies in your browser settings, but this may affect your ability to use our services.`
        },
        {
            heading:`Children’s Privacy:`,
            para:`Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will delete it immediately.`
        },
        {
            heading:`Changes to the Privacy Policy:`,
            para:`We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our services after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.`
        },
        {
            heading:`Contact Information:`,
            para:`Traveller
            `
        }
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-6">
                         <p className=' text-2xl tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        <p className=' text-md mb-3 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	<p className=' text-md mb-3 tracking-[2px] leading-7'>
    Phone: 073-082-327-84
                        </p>
	<p className=' text-md mb-3 tracking-[2px] leading-7'>
    Email: info@travels.co.uk
                        </p>
</div>
    )
}
export default TermandConditions