import React, { useEffect, useState } from "react";
import backgroundImg from '../../assets/images/banner/b2.jpg'
import './Card.css';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from "react-feather";
import './swiper.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination,Autoplay,EffectCoverflow,EffectCreative } from 'swiper/modules';
import c1 from '../../assets/images/comp/01-3-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c2 from '../../assets/images/comp/02-2-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c3 from '../../assets/images/comp/03-1-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c4 from '../../assets/images/comp/04-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c5 from '../../assets/images/comp/05-1-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c6 from '../../assets/images/comp/06-1-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c7 from '../../assets/images/comp/07-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c8 from '../../assets/images/comp/08-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c9 from '../../assets/images/comp/09-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';
import c10 from '../../assets/images/comp/10-qmwokdidlq13oijk6ctnz4fa3oep1diud8tqgnpjcw.webp';

const p1=[
  "Revenue Goal Target Amount Set",
  "Goal Target Dates Set",
  "Account Set Up VAR & End-User",
  "Contract Protocols for VAR & End-User Defined and Implemented",
  "Contracted Revenue Splits defined for ACH",
  "Billing Company End-User Flow for Card/ Checks Payment Plan/Account balancing and Reconcilation",
  "VAR ACH Set Up",
  "Revenue Buckets Defined",
  "Collection Protocols Defined & Set",
  "ACH Withdrawal Process Defined End-User",
  "ACH Set Up for End-User",
  "Responsible parties tagged",
  "Flag Analysis Reports Implemented",
  "Audit Process online",
  "Reports implemented",
  "Financial Dashboard Set up and Monitoring",
]
const Experience=props=>{

  

    return (
        <div className="flex flex-col justify-center items-center p-10" id="process" >            

        
        <p className=" text-lg lg:text-3xl font-bold leading-7 lg:leading-10 tracking-[2px] mb-4 lg:mb-10 text-secondary text-center" >Revenue Cycle Management</p>
        <p className=" text-sm lg:text-lg leading-4 lg:leading-6 tracking-[2px] mb-4 lg:mb-10 text-secondary text-center" >Discover our premier revenue cycle management service, meticulously tailored to maximize the financial efficiency of your healthcare practice. From seamless patient registration to accelerated payment collection, we assure unparalleled effectiveness and profitability. Count on us to streamline billing, claims, and collections, empowering you to prioritize delivering exceptional patient care.</p>
        
        {/* <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-3 p-6 md:p-10">
            <CardFliper/>
            <CardFliper/>
            <CardFliper/>
           
        </div> */}
        <SwiperMiddle/>
        {/* <div className="hidden lg:block mcenter">
        <MyChart1/>
        <MyChart2/>
        <MyChart3/>
        <MyChart4/>
        </div>
       
        <div className="mcenter lg:hidden">
          <MyChartMb1 indexs={[0,1]}/>
          <MyChartMb2 indexs={[15,2]}/>
          <MyChartMb3 indexs={[14,3]}/>
          <MyChartMb3 indexs={[13,4]}/>
          <MyChartMb3 indexs={[12,5]}/>
          <MyChartMb3 indexs={[11,6]}/>
          <MyChartMb3 indexs={[10,7]}/>
          <MyChartMb4 indexs={[9,8]}/>

        </div> */}




       
      </div>
    )
}
const SwiperMiddle=()=> {
  return (
    <>
      <Swiper
        
        slidesPerView={window.screen.width < 700?1:6}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={false}

        className="mySwiper"
      >
        {
          [c1,c2,c3,c4,c5,c6,c7,c8,c9,c10].map(s=>{
            return(
              <SwiperSlide>
              <img src={s} className="h-[100px]"/>
            </SwiperSlide>
            )
          })
        }

        
      </Swiper>
    </>
  );
}
const MyChartMb1 = ({indexs})=>{
  return(<div className="flex gap-1 mb-2 items-center justify-between">
    <div className="bg-primary p-1 rounded-lg   h-[75px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[0]]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg   h-[75px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[1]]}</p></div>
       
    
  </div>)
}

const MyChartMb2 = ({indexs})=>{
  return(<div className="w-full flex gap-1 justify-between ">

  <div>
  <ArrowUp className="mx-auto"/>
  <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[0]]}</p></div>
  <ArrowUp className="mx-auto"/>
  </div>
  <div>
  <ArrowDown className="mx-auto"/>
  <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[0]]}</p></div>
  <ArrowDown className="mx-auto"/>
  </div>
  
  
  
</div>)
}

const MyChartMb3 = ({indexs})=>{
  return(<div className="w-full flex gap-1 justify-between ">

  <div>
  
  <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[0]]}</p></div>
  <ArrowUp className="mx-auto"/>
  </div>
  <div>
  
  <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[1]]}</p></div>
  <ArrowDown className="mx-auto"/>
  </div>
  
  
  
</div>)
}

const MyChartMb4 = ({indexs})=>{
  return(<div className="flex gap-1 mb-2 items-center justify-between">
    <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[0]]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg   h-[79px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[indexs[1]]}</p></div>
       
    
  </div>)
}
const MyChart1 = ()=>{
  return(<div className="flex gap-1 mb-2 items-center justify-between">
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[0]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[1]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[2]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[3]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[4]}</p></div>
    <ArrowRight/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[10px] justify-center"><p className="text-white">{p1[5]}</p></div>
    
    
  </div>)
}

const MyChart2 = ()=>{
  return(<div className="w-full flex gap-1 justify-between ">

    <div>
    <ArrowUp className="mx-auto"/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[15]}</p></div>
    <ArrowUp className="mx-auto"/>
    </div>
    <div>
    <ArrowDown className="mx-auto"/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[6]}</p></div>
    <ArrowDown className="mx-auto"/>
    </div>
    
    
    
  </div>)
}

const MyChart3 = ()=>{
  return(<div className="w-full flex gap-1 justify-between ">
    <div>
    
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[14]}</p></div>
    <ArrowUp className="mx-auto"/>
    </div>
    <div>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[7]}</p></div>
    <ArrowDown className="mx-auto"/>
    </div>
    
    
    
  </div>)
}

const MyChart4= ()=>{
  return(<div className="flex gap-1 mb-2 items-center justify-between">
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[8]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[9]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[10]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[11]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[12]}</p></div>
    <ArrowLeft/>
    <div className="bg-primary p-1 rounded-lg  h-[70px] w-[160px] text-center flex items-center text-[11px] justify-center"><p className="text-white">{p1[13]}</p></div>
    
    
  </div>)
}





function RecycleChart() {
  useEffect(() => {
    const colors = ['green', 'yellow', 'red', 'purple'];

    const container = document.getElementById('recycle-chart');
    let w= window.screen.width;

    const radius = w>600? 200:160;
    const numItems = 16;
    const angleIncrement = (2 * Math.PI) / numItems;

    for (let i = 0; i < numItems; i++) {
      const angle = i * angleIncrement;

      const x = 110 + radius * Math.cos(angle);
      const y = 110 + radius * Math.sin(angle);

      console.log(x,y,i);

      const circle = document.createElement('div');
      circle.classList.add('absolute','mx-2','text-[8px]','lg:text-[10px]','text-center', 'rounded-lg', 'bg-primary', 'w-[65px]','lg:w-[70px]','p-[3px]','lg:p-1',  'flex', 'justify-center', 'items-center','border-none');
      circle.style.left = x + 'px';
      circle.style.top = y + 'px';

      const text = document.createElement('span');
      text.textContent = `${i}. Revenue Goal Target Amount Set`;
      text.classList.add('text-white');

      circle.appendChild(text);
      container.appendChild(circle);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-[70vh] p-10">
      <div id="recycle-chart" className="relative w-[300px] h-[300px]   border-2 border-black rounded-full"></div>
    </div>
  );
}






const CardFliper=props=>{

    return (
        <div className="flex flex-col justify-center items-center p-3">
            <div className="relative rounded-full bg-primary h-[120px] w-[120px]  text-[60px] text-[60px] flex justify-center items-center group ">
            <i className="zmdi zmdi-accounts-add text-white cardIcon rounded-full bg-primary h-full w-full  text-[60px] text-[60px] flex justify-center items-center group-hover:bg-white group-hover:text-primary ease-in duration-300"></i>
            <div className="rounded-full bg-white h-[30px] w-[30px] text-primary text-[20px] font-bold absolute left-[-1%] bottom-[2%] flex justify-center items-center group-hover:bg-primary group-hover:text-white ease-in duration-300"><p>1</p> </div>
            </div>
  
  <p className="text-lg leading-9 tracking-[1px] text-mDarkGray mt-2 text-center font-bold">Needs Assessment</p>
  <p className="text-lg leading-9 tracking-[1px] text-mDarkGray mt-2 text-center">Schedule a comprehensive consultation to understand your specific needs, challenges, and goals.</p>

</div>

    )
}

export default Experience
