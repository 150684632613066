import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import './swiper.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import './button.css';

import { EffectFade, Navigation, Pagination,Autoplay,EffectCoverflow,EffectCreative } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from "../Button/Button";
import Chart from "./Chart";
import { Check, Circle, Facebook, Linkedin, Phone, Twitter } from "react-feather";
import Second from '../../assets/images/home/thirdSection/2.jpg';
import Banner from '../../assets/images/blog/user-img-1.jpg';
import tables from '../../assets/images/blog/tables.jpg';
import img3 from '../../assets/images/blog/blog-titleimg.jpg';
import ImageReactangle from '../Contact/ImageReactangle';
import Image from '../Contact/Image';
import Footer from "../footer";

import B1 from "../../assets/images/achieving.webp";
import B2 from "../../assets/images/banner/b2.jpg";
import B3 from "../../assets/images/revenue-cycle.webp";
import ic1 from "../../assets/images/icons/1.svg";
import ic2 from "../../assets/images/icons/2.svg";
import ic3 from "../../assets/images/icons/3.svg";
import ic4 from "../../assets/images/icons/4.svg";

import CustomizeSection from "./CustomizeSection";
import Navbar from "../navbar";

import { TopSectionData } from "../../Service/Data";
import Card from "./Card";
import BillingPartner from "./BillingPartner";
import Operations from "./Operations";
import Experience from "./Experience";
import Speciality from "./Speciality";
import Whyus from "./Whyus";
import Stories from "./Stories";
import Getintouch from "./GetInTouch";

const TopSection=props=>{
  const [coverIndex,setCoverIndex]=useState(0)
  const [refresh,setRefresh]=useState(new Date().getTime())

  
  const recursiveFunc=()=>{
    setTimeout(()=>{
      if(coverIndex==0)
      {
        setCoverIndex(1)
      }
    else
    {
      setCoverIndex(0)
    }

    setRefresh(new Date().getTime())
    
    },6000)

  }


    useEffect(()=>{
        AOS.init();
        recursiveFunc();
    },[])

    const cardContent = [
      {
        heading:`Medical Coding`,
        para:`Accurate and efficient medical coding ensures that healthcare providers receive appropriate reimbursement for services rendered. Our company provides expert medical coding services, ensuring compliance with all relevant coding guidelines and regulations.`,
        icon:ic1
      },
      {
        heading:`Claims Processing`,
        para:`Timely and accurate claims processing is crucial for maintaining cash flow and reducing revenue cycle inefficiencies. We specialize in handling claims submission, follow-up, and resolution, maximizing reimbursements while minimizing denials and delays.`,
        icon:ic2
      },
      {
        heading:`Revenue Cycle Management`,
        para:`Our comprehensive revenue cycle management services streamline the entire billing process, from patient registration to payment posting. By optimizing workflows and leveraging technology, we help healthcare organizations improve financial performance and focus on delivering quality patient care.`,
        icon:ic3
      },
      {
        heading:`Compliance and Audit Support`,
        para:`Staying compliant with ever-changing healthcare regulations and undergoing audits can be daunting tasks. Our team provides proactive compliance monitoring, conducts regular audits, and offers guidance on maintaining adherence to industry standards, reducing the risk of penalties and fines`,
        icon:ic4
      },
    ]

    return(<div>
      <Navbar />
<div className="relative w-full h-[85vh] lg:h-[100vh] overflow-hidden mt-[-130px]" id='home'>
  
{/* <BackSwiper/> */}
<div className="absolute h-full w-[6%]  z-[100] bottom-0 right-0 flex flex-col justify-center items-center overflow-hidden p-1">
<a href="#" className="circleButton m-1" style={coverIndex==0?{borderColor:'#4cc3da'}:{borderColor:'#000'}}  onClick={()=>{setCoverIndex(0)}} >1</a>
<a href="#" className="circleButton m-1" style={coverIndex==1?{borderColor:'#4cc3da'}:{borderColor:'#000'}} onClick={()=>{setCoverIndex(1)}}>2</a>
</div>

{
  
    coverIndex==0?<Component1 src={B3} coverIndex={coverIndex} heading={TopSectionData.heading} para1={TopSectionData.para} para2={TopSectionData.para2}/>:
    <Component2 src={B1} coverIndex={coverIndex} heading={TopSectionData.heading2} para1={TopSectionData.para2} para2={TopSectionData.para2}/>
  
}



{/* <div className="hidden lg:block absolute z-[2] w-[95%] md:w-[90%] lg:w-[85%] left-[7%] bottom-[-100px] ">
<div className="bg-secondary p-5 rounded-lg" data-aos="fade-up"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="1500">
        <InputSection airportDep={airportDep} airportArv={airportArv}/>
</div>
</div> */}

</div>

{/* <div className="block lg:hidden w-[95%] mx-auto mt-2">
<div className="bg-secondary p-5 rounded-lg" data-aos="fade-up"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="1500">
        <InputSection airportDep={airportDep} airportArv={airportArv}/>
</div>
</div> */}
<div className= "p-3 md:p-5 w-full md:w-[90%] lg:w-[87%] mx-auto mt-4 md:mt-[-8%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 flex justify-center items-center gap-2">
  {
    cardContent.map(x=>{
      return (<Card data={x}/>)
    })
  }



</div>
<BillingPartner/>
<Operations/>
<Experience/>
<Speciality/>
<Whyus/>
{/* <Stories/> */}
<Getintouch/>


<Footer/>



</div>
    )
}


const Component1=({src,heading,para1,para2})=>{
  return(
    <div className="relative h-full w-full">
  <div className="absolute bg-secondary bg-opacity-50 w-full h-full z-[-1]"></div>
  <div className="absolute z-[-11] w-full h-full flex justify-right items-center bg-black">
  <img src={src} className="imageAnimationClass" alt="1" loading="lazy"/>
  </div>
  <div className="absolute z-[2] w-full h-full  grid grid-cols-1 md:grid-cols-3">
<div >
</div>
<div  className="h-full w-[92%] mx-auto flex flex-col items-end justify-center p-5 md:col-span-2 overflow-hidden">

<p  className="animateHeading tracking-[2px] px-3 text-md md:text-xl font-[900] leading-5 md:leading-16   text-white text-left md:text-right">{TopSectionData.heading}</p>
<p  className="animateHeading tracking-[2px] px-3 text-md md:text-xl font-[900] leading-5 md:leading-16   text-white text-left md:text-right">{TopSectionData.heading12}</p>
<p  className="animateHeading tracking-[2px] px-3 text-md md:text-xl font-[900] leading-5 md:leading-16   text-white text-left md:text-right">{TopSectionData.heading13}</p>
<div className="flex items-center" data-aos="fade-left" data-aos-duration="1000">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] pl-2 pr-3 text-sm md:text-md font-[700] leading-5 md:leading-8  text-white text-left md:text-right mt-2`}>{TopSectionData.para}</p>
  </div> 
<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] pl-2 pr-3 text-sm md:text-md font-[700] leading-5 md:leading-8  text-white text-left md:text-right mt-2`}>{TopSectionData.para12}</p>
  </div> 

<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] pl-2 pr-3 text-sm md:text-md font-[700] leading-5 md:leading-8  text-white text-left md:text-right mt-2`}>{TopSectionData.para13}</p>
  </div> 


<button className="btn ml-1 mt-3" onClick={() => {
    window.location.href = `tel:123456789`;
  }}><Phone/>  Partner with Us Today
</button>
</div>
</div>
  </div>
  )
}

const Component2=({src,heading,para1,para2})=>{
  return(
    <div className="relative h-full w-full">
  <div className="absolute bg-secondary bg-opacity-40 w-full h-full z-[-1]"></div>
  <div className="absolute z-[-11] w-full h-full flex justify-right items-center bg-black">
  <img src={src} className="imageAnimationClass" alt="1" loading="lazy"/>
  </div>
  <div className="absolute z-[2] w-full h-full  grid grid-cols-1 md:grid-cols-3">

<div  className="h-full w-[90%] mx-auto flex flex-col  items-start justify-end md:justify-center p-5 md:col-span-2 overflow-hidden">
<p  className="animateHeading tracking-[3px] px-3 text-md md:text-2xl font-[900] leading-5 md:leading-12   text-white text-left md:text-right">{TopSectionData.heading2}</p>

<div className="flex items-center" data-aos="fade-left" data-aos-duration="1000">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] px-3 text-sm md:text-md font-[600] leading-5 md:leading-8  text-white text-left md:text-right mt-3`}>{TopSectionData.para2}</p>
  </div> 

<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] px-3 text-sm md:text-md font-[600] leading-5 md:leading-8  text-white text-left md:text-right mt-1`}>{TopSectionData.para22}</p>
  </div> 

<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] px-3 text-sm md:text-md font-[600] leading-5 md:leading-8  text-white text-left md:text-right mt-1`}>{TopSectionData.para23}</p>
  </div> 

<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] px-3 text-sm md:text-md font-[600] leading-5 md:leading-8  text-white text-left md:text-right mt-1`}>{TopSectionData.para24}</p>
  </div> 

<div className="flex items-center" data-aos="fade-left" data-aos-duration="500">
<Check className="text-secondary text-bold text-[20px]" size={30}/>
<p  className={`tracking-[1px] px-3 text-sm md:text-md font-[400] leading-5 md:leading-8  text-white text-left md:text-right mt-1`}>{TopSectionData.para25}</p>
  </div> 





<button className="btn ml-1 mt-3" onClick={() => {
    window.location.href = `tel:123456789`;
  }}> <Phone/> Find out Now
</button>
</div>
<div >
</div>

</div>
  </div>
  )
}



export default TopSection