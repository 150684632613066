export const TopSectionData={
    heading:`Experience Seamless Integration with Our Billing Services`,
    heading12:`Your Dedicated Team, Working as an Extension of Your Staff `,
    heading13:`HealthCare MBS `,
    para:'Enjoy the benefits of a fully integrated billing team without the overhead',
    para12:'Our experts work alongside your staff to ensure precise and efficient billing',
    para13:'Boost your practice’s productivity and maximize revenue with our seamless support',
    heading2:'Standalone Services Offered',
    para2:'Eligibility & Benefits Verification Prior to Patient Visit',
    para22:'Medical Services Pre-Authorization',
    para23:'Patient Appointment Scheduling',
    para24:'Medical Billing Fee Schedule Analysis',
    para25:'Medical Billing Audit and many more…',
}