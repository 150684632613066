import React, { useEffect, useState } from "react";
import backgroundImg from '../../assets/images/banner/b2.jpg'
import './Card.css';
import ans1 from '../../assets/images/comp/Anesthesiology-Medical-Billing-Services-1.webp'
import ans2 from '../../assets/images/comp/Behavioral-Health-Billing-Services-1.webp'
import ans3 from '../../assets/images/comp/Cardiology-Medical-Billing-Services-1.webp'
import ans4 from '../../assets/images/comp/Chiropractic-Billing-Services-1.webp'
import ans5 from '../../assets/images/comp/Colon-and-Rectal-Billing-Services-1.webp'
import ans6 from '../../assets/images/comp/Dermatology-Billing-Services-1.webp'
import ans7 from '../../assets/images/comp/Gastroenterology-Billing-Services-1.webp'
import ans8 from '../../assets/images/comp/General-Surgery-Billing-Services-1.webp'
import ans9 from '../../assets/images/comp/Internal-Medicine-Billing-Services-1.webp'
import ans10 from '../../assets/images/comp/Neurosurgery-Billing-Services-1.webp'
import ans11 from '../../assets/images/comp/Oncology-Medical-Billing-Services-2.webp'
import ans12 from '../../assets/images/comp/Ophthalmology-Billing-Services-1.webp'
import ans13 from '../../assets/images/comp/Orthopedic-Surgery-Medical-Billing-Services-1.webp'
import ans14 from '../../assets/images/comp/Pain-Management-Billing-Services-1.webp'
import ans15 from '../../assets/images/comp/Pediatric-Medical-Billing-Service-1.webp'
import ans16 from '../../assets/images/comp/Physical-Therapy-Billing-Services-1.webp'
import ans17 from '../../assets/images/comp/Plastic-Surgery-Billing-Services-1.webp'
import ans18 from '../../assets/images/comp/Radiology-Billing-Services-1.webp'
import ans19 from '../../assets/images/comp/Urology-Billing-Services-1.webp'
import ans20 from '../../assets/images/comp/Vascular-Surgery-Billing-Services-1.webp'

const aaa = [
    {img:ans1,text:`Anesthesiology Billing Services`},
    {img:ans2,text:`Behavioral Health Billing Services`},
    {img:ans3,text:`Cardiology Billing Services`},
    {img:ans4,text:`Chiropractic Billing Services`},
    {img:ans5,text:`Colon and Rectal Billing Services`},
    {img:ans6,text:`Dermatology Billing Services`},
    {img:ans7,text:`Gastroenterology Billing Services`},
    {img:ans8,text:`General Surgery Billing Services`},
    {img:ans9,text:`Internal Medicine Billing Services`},
    {img:ans10,text:`Neurosurgery Billing Services`},
    {img:ans11,text:`Oncology Medical Billing Services`},
    {img:ans12,text:`Ophthalmology Billing Services`},
    {img:ans13,text:`Orthopedic Surgery Billing Services`},
    {img:ans14,text:`Pain Management Billing Services`},
    {img:ans15,text:`Pediatric Medical Billing Services`},
    {img:ans16,text:`Physical Therapy Billing Services`},
    {img:ans17,text:`Plastic Surgery Billing Services`},
    {img:ans18,text:`Radiology Billing Billing Services`},
    {img:ans19,text:`Urology Billing Billing Services`},
    {img:ans20,text:`Vascular Surgery Billing Services`},
]

const Speciality=props=>{

    const cardContent = [
        {
            heading:`Small Practices`,
            para:`We have dedicated departments for small practices such as chiropractic, mental health, physical therapy, DME, and various other medical and non-medical practices.`
        },
        {
            heading:`General Practices`,
            para:`We dedicate resources to work exclusively on individual practices, becoming experts in billing your claims accurately for faster and enhanced reimbursement.`
        },
        {
            heading:`Specialty Practices`,
            para:`We hire seasoned professionals who specialize in your medical field to ensure top-quality billing services and optimal reimbursement.`
        },
    
    ]

    return (
        <div className="w-[95%] lg:w-[80%] mx-auto relative flex flex-col justify-center items-center my-0 md:my-8 p-4 md:p-7 md:p-10" id="special">            

        
        <p className="text-lg lg:text-3xl font-bold leading-8 lg:leading-10 tracking-[2px] my-5 text-secondary text-center" >Our unique operational approach enables us to deliver on our commitments effectively</p>
        
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-3 p-3 md:p-10">
            {
                cardContent.map(x=>{
                    return (<CardFliper data={x}/>)
                })
            }
            
           
        </div>

        <div className="col-span-full mt-5 md:mt-0 p-6 md:p-10 flex flex-wrap justify-center">
                {
                    aaa.map(x=>{
                        return(
                            <div className="p-2 flex flex-col gap-3 justify-center items-center max-w-[150px]">
                                <img src={x.img} className="h-[45px] w-[45px]"/>
                                <p className="text-md font-[600] text-center">{x.text}</p>
                            </div>
                        )
                    })
                }
            </div>

        <div className="w-[88%] md:w-[80%] mx-auto p-9 bg-secondary rounded-lg flex flex-col md:flex-row justify-around mt-5">
            <p className="text-white leading-9 tracking-10 text-lg font-bold">Experience excellence like never before by trying out our unique services</p>
            <button className="btn">  Contact Us
</button>
        </div>
      </div>
    )
}

const CardFliper=({data})=>{

    return (
       
<div class="card3">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="card3-inner p-3">
    <p className="text-2xl text-primary  leading-8 tracking-[1px] my-5  text-left font-bold" >{data.heading}</p>
    <p className="text-lg text-white leading-8 tracking-[1px] my-5  text-left" >{data.para}</p>
    </div>
</div>
    )
}

export default Speciality
