import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TopSection=props=>{

    return(

        <div className='relative mt-[100px] pb-2/3 h-[35vh] flex  justify-center items-center'>
            <div className="">
            <p className="text-5xl text-center mb-3">
                         Contact Us
                     </p>
                
            </div>
	
</div>
    )
}
export default TopSection