import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions=props=>{

    const data=[
        {
            heading:``,
            para:`In order to keep your payments safe against the Flights, Hotels, Umrah and Hajj packages, transport, visa & Insurance that you buy, Tawheed Travel facilitates the safest online payment methods via a 3D secure link. The methods we allow include:`
        },
        {
            heading:`1. Online Money Transfer`,
            para:``
        },
        {
            heading:`2. Payment via Cash`,
            para:``
        },
        {
            heading:`3. Debit Cards`,
            para:``
        },
        {
            heading:`4. Credit cards`,
            para:``
        },
        {
            heading:`5. Payment Cards`,
            para:``
        },
        {
            heading:`6. Cheque payment (not preferred at all as it takes more than 10 days to clear)`,
            para:``
        },
        {
            heading:``,
            para:`These are some of the ways we consider are safe for making payments online. You may choose the method that is convenient for you and make your payment that way. With safe payment, all issues can be kept aside and the way you buy your favorite holiday packages can be eased.
            `
        }
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-2">
                         <p className=' text-1xl font-[600] tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        <p className=' text-md mb-1 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	
</div>
    )
}
export default TermandConditions