import React, { useEffect, useState } from "react";
import backgroundImg from '../../assets/images/banner/b4.jpg'
import './form.css';
import ContactForm from "../Contact/ContactForm";
import { Facebook, Instagram, Linkedin, Youtube } from "react-feather";


const Getintouch=props=>{

    return (
        <div className="relative  mt-10 w-full" id="touch">            
  <div className="absolute bg-secondary bg-opacity-70 w-full h-full z-[-1]"></div>
  <div className="absolute z-[-11] w-full h-full flex justify-right items-center bg-black">
  <img src={backgroundImg} className="h-full w-full" alt="1" loading="lazy"/>
  </div>
      
        <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] mx-auto">
      <Accordian/>
<ContactForm/>
        </div>
       
      </div>
    )
}



const Accordian=props=>{

    return (
       <div className="py-20 px-3 md:px-10">
        <div className=" text-white font-light mb-2 text-sm max-md:font-medium lg-1:text-[18px]">
                        <p className="text-gray-light tracking-[3px] mb-2">Location:</p>
                        <p className="mb-5">1309 Coffeen Ave, Suite 1200<br/>
Sheridan, Wyoming 82801</p>

<p className="text-gray-light tracking-[3px] my-2">Main Line:</p>
                        <p>(206) 317-1579</p>

                        <div className="flex gap-4 my-7 md:my-10">
                            <Facebook/>
                            <Linkedin/>
                            <Instagram/>
                            <Youtube/>

                        </div>

                    </div>
       </div>

    )
}

export default Getintouch
