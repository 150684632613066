import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const AboutusDetail=props=>{

    const data=[
        {
            heading:`WHO WE ARE?`,
            para:`Tawheed Travel was established in 2020 with the aim of broadcasting and simplifying the sometimes complex Hajj and Umrah industry. Our Clever Technology enables us to compare a wide network of tour operators, airports and hotels to build a large number of Hajj and Umrah Packages to suit your needs. As a travel company with over 20 years of experience working on Hajj and Umrah packages, we are experienced in providing you with private and explicit advice on the blessed pilgrimage of Hajj or Umrah. Over the years we have helped thousands of customers through travel and thanks to the excellent feedback from our customers we have built a very good reputation in the industry. In addition to our Hajj and Umrah offerings, we can arrange “halal holidays to Spain, Turkey, Morocco, Canada and Jordan as well as a guided tour to Masjid Al-Aqsa.`
        },
        {
            heading:`WHY OUR CUSTOMERS CHOOSE US?`,
            para:`Experts in Hajj, Umrah and Islamic Tours, our travel advisors give you reliable and clear advice on planning and booking your trip and travel information itself. By the grace of Allah, we are focused on ensuring that our pilgrims are taught to the best of our ability during their pilgrimage and Umrah, but we will never pass a promise or fulfill it, we can choose to be faithful even when there is doubt in the promise and to bring more. The pilgrimage to Hajj and Umrah is unlike any other holiday so we believe that bespoke, one to one open service Weill ensures that customer expectations are clear.
            We have a wide variety of Hajj and Umrah Deals from budget to luxury and will be clear when something is budget and when something is luxury. We have met with many companies that will promise for example that a hotel is a 5 minute walk from a major mosque in Makkah or Medina and actually a trip is like 15 minutes. We believe it is best to tell the customer that the hotel is a 15 minute walk and the customer is happy if they can walk for 10 minutes. In addition, our company manages resources and has strong relationships with hotels in Makkah and Medina. We offer easy-to-use, affordable and affordable packages to meet the needs of everyone in the community. Our packages speak to all classes and citizens of the UK. Our services maintain a high level of speed, speed and reliability. Also, we have access to all flights to Saudi Arabia so you can be comfortable with your choice. Honesty and openness are key to tawheedtravel.co.uk`
        },
        {
            heading:`OUR CUSTOMER CARE FACILITIES`,
            para:`Our tour guides will support you by planning and booking your trip and your trip to the sanctuary. We can help solve the problems facing our pilgrims from health issues to arranging ways to add transportation or sightseeing. We will have medicine / guides / staff present with us throughout our Hajj journey. Please call us on 020 4518 3223 if you are experiencing problems while traveling. We will also provide you with a Whatsapp contact while you are traveling to help you with any problems or to answer any questions. We look forward to seeing you soon!`
        },
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-10">
                         <p className=' text-4xl tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        <p className=' text-md mb-3 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	
</div>
    )
}
export default AboutusDetail