import React, { useEffect, useState } from "react";

import './billing.css';

import billingImg from '../../assets/images/Picture1.png'
import ans1 from '../../assets/images/comp/Anesthesiology-Medical-Billing-Services-1.webp'
import ans2 from '../../assets/images/comp/Behavioral-Health-Billing-Services-1.webp'
import ans3 from '../../assets/images/comp/Cardiology-Medical-Billing-Services-1.webp'
import ans4 from '../../assets/images/comp/Chiropractic-Billing-Services-1.webp'
import ans5 from '../../assets/images/comp/Colon-and-Rectal-Billing-Services-1.webp'
import ans6 from '../../assets/images/comp/Dermatology-Billing-Services-1.webp'
import ans7 from '../../assets/images/comp/Gastroenterology-Billing-Services-1.webp'
import ans8 from '../../assets/images/comp/General-Surgery-Billing-Services-1.webp'
import ans9 from '../../assets/images/comp/Internal-Medicine-Billing-Services-1.webp'
import ans10 from '../../assets/images/comp/Neurosurgery-Billing-Services-1.webp'
import ans11 from '../../assets/images/comp/Oncology-Medical-Billing-Services-2.webp'
import ans12 from '../../assets/images/comp/Ophthalmology-Billing-Services-1.webp'
import ans13 from '../../assets/images/comp/Orthopedic-Surgery-Medical-Billing-Services-1.webp'
import ans14 from '../../assets/images/comp/Pain-Management-Billing-Services-1.webp'
import ans15 from '../../assets/images/comp/Pediatric-Medical-Billing-Service-1.webp'
import ans16 from '../../assets/images/comp/Physical-Therapy-Billing-Services-1.webp'
import ans17 from '../../assets/images/comp/Plastic-Surgery-Billing-Services-1.webp'
import ans18 from '../../assets/images/comp/Radiology-Billing-Services-1.webp'
import ans19 from '../../assets/images/comp/Urology-Billing-Services-1.webp'
import ans20 from '../../assets/images/comp/Vascular-Surgery-Billing-Services-1.webp'

const aaa = [
    {img:ans1,text:`Anesthesiology Billing Services`},
    {img:ans2,text:`Behavioral Health Billing Services`},
    {img:ans3,text:`Cardiology Billing Services`},
    {img:ans4,text:`Chiropractic Billing Services`},
    {img:ans5,text:`Colon and Rectal Billing Services`},
    {img:ans6,text:`Dermatology Billing Services`},
    {img:ans7,text:`Gastroenterology Billing Services`},
    {img:ans8,text:`General Surgery Billing Services`},
    {img:ans9,text:`Internal Medicine Billing Services`},
    {img:ans10,text:`Neurosurgery Billing Services`},
    {img:ans11,text:`Oncology Medical Billing Services`},
    {img:ans12,text:`Ophthalmology Billing Services`},
    {img:ans13,text:`Orthopedic Surgery Billing Services`},
    {img:ans14,text:`Pain Management Billing Services`},
    {img:ans15,text:`Pediatric Medical Billing Services`},
    {img:ans16,text:`Physical Therapy Billing Services`},
    {img:ans17,text:`Plastic Surgery Billing Services`},
    {img:ans18,text:`Radiology Billing Billing Services`},
    {img:ans19,text:`Urology Billing Billing Services`},
    {img:ans20,text:`Vascular Surgery Billing Services`},
]


const BillingPartner=props=>{

    const getCircle=()=>{
        return (<div className="mt-3 h-[10px] w-[10px] rounded-full bg-secondary"></div>)
    }

    return (
        <div className="p-3 md:p-10 grid grid-cols-1 md:grid-cols-2" id="solution">
            
            <div className="mx-5 relative p-5 md:p-9">


                <div className="triangle">                   
                </div>
                
                <img src={billingImg} className=" h-full w-full"/>
                <p className=" ml-[90px] text-md md:text-lg font-[500] text-secondary tracking-[1px]">Certified Billers and Coders</p>
               
                <div className="absolute top-[10%] right-[-3%] md:right-[2%] bg-white rounded-lg p-3 md:p-5 shadow-lg flex">
                <i className="zmdi zmdi-accounts-add text-white cardIcon rounded-full bg-primary h-[60px] w-[60px]  text-[40px] text-[40px] flex justify-center items-center"></i>
                <div className="mx-1 px-2"> 
                    <div className="mycounter text-md md:text-lg">+</div>
                    <p className="font-bold text-secondary tracking-[1px]">Satisfied Clients</p>
                </div>
                </div>


            </div>


            <div className="mt-5 md:mt-0 p-6 md:p-10">
                <p className="text-secondary text-lg leading-2 tracking-[2px] font-bold">Billing Solution</p>
                <p className="text-lg lg:text-2xl font-bold mt-3 tracking-[2px]">HealthCare MBS</p>
                <div className="w-full mt-5">
                    <p className="ml-5 lg:ml-10  text-md lg:text-lg leading-6 lg:leading-7 tracking-[1px]">These core services lie at the heart of our medical billing company, guaranteeing efficiency, precision, and adherence to regulations for healthcare providers across various scales. At our medical billing company, we believe in flexibility and transparency. Unlike many others in the industry, we don't tie our clients down with contracts or burden them with sign-up fees. Your trust and satisfaction are what matter most to us, and we're committed to earning them through exceptional service and unwavering support.</p>
                </div>

                {/* <div className="w-full mt-5 flex">
                    {getCircle()}
                    <p className=" ml-2 text-md lg:text-lg leading-6 lg:leading-7 tracking-[1px]"><span className="font-bold">Save time</span>  – Focus more on patient care rather than administrative tasks.</p>
                    </div>

                    <div className="w-full mt-5 flex">
                    {getCircle()}
                    <p className=" ml-2 text-md lg:text-lg leading-6 lg:leading-7 tracking-[1px]"><span className="font-bold">Boost revenue</span>   – Get faster reimbursements and achieve increased financial stability.</p>
                    </div> */}
            </div>


            {/* <div className="col-span-full mt-5 md:mt-0 p-6 md:p-10 flex flex-wrap justify-center">
                {
                    aaa.map(x=>{
                        return(
                            <div className="p-2 flex flex-col gap-3 justify-center items-center max-w-[150px]">
                                <img src={x.img} className="h-[45px] w-[45px]"/>
                                <p className="text-md font-[600] text-center">{x.text}</p>
                            </div>
                        )
                    })
                }
            </div> */}

      </div>
    )
}

export default BillingPartner