import React, { useEffect, useState } from "react";
import backgroundImg from '../../assets/images/hippa.jpg'
import './Card.css';
import { Mail, Phone } from "react-feather";


const Whyus=props=>{

    return (
        <div className="w-[95%] md:w-[82%] mx-auto relative flex flex-col justify-center items-center my-7  p-6 lg:p-10" id="whyus">            

        
        <p className="text-lg lg:text-3xl font-bold leading-7 lg:leading-10 tracking-[2px] my-5 text-secondary text-center" >HealthCare MBS comprises HIPAA-Compliant and Certified Members dedicated to providing exceptional healthcare billing services</p>
        
        
        <div className="w-full grid grid-cols-1 md:grid-cols-2  gap-10 my-3 p-2">

           <div className="grid grid-cols-1 gap-1">
            {/* <TextCard/>
            <TextCard/>
            <TextCard/>
            <TextCard/> */}
            <p className="text-md lg:text-lg  leading-5 lg:leading-6 tracking-[1px] my-1 " >
            At Healthcare MBS, our mission is to prioritize client satisfaction and maximize revenue for healthcare professionals, empowering them to focus on their passion for delivering exceptional patient care without the burden of billing complexities. We strive to streamline the billing process, navigate the intricate landscape of insurance claims and reimbursement, and resolve billing issues and denials, ensuring fair compensation for services rendered. Through transparent and timely communication, we keep clients informed every step of the way, staying ahead of industry trends and regulatory changes to ensure compliance and peace of mind. By fostering long-term relationships built on trust, expertise, and dedication, we support the well-being and success of healthcare professionals, enabling them to deliver the highest quality care. Ultimately, our goal is to make a positive impact on the healthcare community, one billing solution at a time</p>
           </div>
           
           <div className="card3-inner p-1 ">
            <img src={backgroundImg} className="h-full w-full"/>
           {/* <div className="grid grid-cols-1 md:grid-cols-2">
            
            <div className="flex p-5 justify-start items-center">
            <i className="zmdi zmdi-accounts-add  cardIcon rounded-full h-[60px] w-[60px]  text-[40px] text-[40px] flex justify-center items-center"></i>
            <p className=" tracking-[1px]">  Dedicated support</p>
            </div>

            <div className="flex p-5 justify-start items-center">
            <i className="zmdi zmdi-accounts-add  cardIcon rounded-full h-[60px] w-[60px]  text-[40px] text-[40px] flex justify-center items-center"></i>
            <p className=" tracking-[1px]">  HIPAA-Compliance</p>
            </div>

            <div className="flex p-5 justify-start items-center">
            <i className="zmdi zmdi-accounts-add  cardIcon rounded-full h-[60px] w-[60px]  text-[40px] text-[40px] flex justify-center items-center"></i>
            <p className=" tracking-[1px]">  Certified Team</p>
            </div>

            <div className="flex p-5 justify-start items-center">
            <i className="zmdi zmdi-accounts-add  cardIcon rounded-full h-[60px] w-[60px]  text-[40px] text-[40px] flex justify-center items-center"></i>
            <p className=" tracking-[1px]"> Tailored Solutions</p>
            </div>

            <div className="flex p-5 justify-start items-center">
            <Phone className=" bg-primary rounded-full text-white h-[60px] w-[60px] p-3 hover:bg-white hover:text-primary ease-in duration-300" role="button"/>
            <div className="mx-2">
            <p className=" tracking-[1px]"> Call Us</p>
            <p className=" tracking-[1px]"> +1 (281) 766 3996</p>
                
            </div>
            </div>

            <div className="flex p-5 justify-start items-center">
            <Mail className=" bg-primary rounded-full text-white h-[60px] w-[60px] p-3 hover:bg-white hover:text-primary ease-in duration-300" role="button"/>
            <div className="mx-2">
            <p className=" tracking-[1px]"> Mail Us</p>
            
                
            </div>
            </div>
            
           </div> */}
           </div>



           
        </div>


      </div>
    )
}

const TextCard=props=>{

    return (
       
<div className="">

    
    <p className="text-lg  leading-8 tracking-[1px] my-5  text-left font-bold" ><span className="text-primary">{'>>'}</span> Improved Cash Flow</p>
    <p className="text-lg  leading-6 tracking-[1px] my-2  text-left" >Accelerate revenue with optimized billing strategies and efficient financial processes.</p>
    
</div>
    )
}

export default Whyus
