import React, { useState } from "react";
import First from '../../assets/images/home/thirdSection/3.jpg';
import { ArrowRight } from "react-feather";
import MyInput from "../Input/MyInput";
import Button from "../Button/Button";
import Service from "../../Service/Service";



const ContactForm=props=>{

    const [name,setName]=useState();
    const [email,setEmail]=useState();
    const [phone,setPhone]=useState();
    const [subject,setSubject]=useState();
    const [message,setMessage]=useState();
    const [toast,showToast]=useState(false)
    const [error,setError]=useState('')

    const submit=()=>{
        if(!name){
            setError('Please Enter Name');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!email){
            setError('Please Enter Email');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!phone){
            setError('Please Enter Phone no');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!subject){
            setError('Please Enter Subject');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!message){
            setError('Please Enter Message');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
        let params={
            name,
            email,
            phone,
            subject,
            message
        }
        Service.addContact(params).then(response=>{
          document.getElementById('my_modal_5').showModal()
        }).catch(err=>{
            console.log(err)
        })
    }


    return(
        <div className="my-3">
                  {
          toast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info bg-[red]">
      <span className="text-white text-lg">{error}</span>
    </div>
          </div>
  
    
  </div>
        }

            <div className='flex flex-wrap'>

                <div className='w-full flex flex-col items-center justify-center px-5 py-10 lg-1:px-14 lg-1:py-9 bg-secondary rounded-lg'>
                    
                    <p className='text-white text-3xl tracking-[1px] mb-2'>
                    CONTACT
                        </p>

                        
                        <div className="flex flex-col gap-3 py-3 w-full">
                            <MyInput
                            label={`Name`}
                            placeholder='Name'
                            onChange={(e)=>setName(e.target.value)}
                            />
                            <MyInput
                            label={`Email`}
                            placeholder='Email'
                            onChange={(e)=>setEmail(e.target.value)}
                            />
                            <MyInput
                            label={`Phone`}
                            placeholder='Phone'
                            onChange={(e)=>setPhone(e.target.value)}
                            />
                            <MyInput
                            label={`Subject`}
                            placeholder='Subject'
                            onChange={(e)=>setSubject(e.target.value)}
                            />
                            <MyInput
                            label={`Message`}
                            placeholder='Message'
                            type='textarea'
                            inputClass='h-[120px]'
                            onChange={(e)=>setMessage(e.target.value)}
                            />
                        </div>
                        
                        <button className="btn rounded-xl bg-primary my-2" onClick={submit}>Consult today</button>
                    </div>
                
               

            </div>

            <dialog id="my_modal_5" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={()=>{window.location.reload()}}>✕</button>
    </form>
    <p className="text-xl text-white p-8">Contact Submitted</p>
  </div>
</dialog>            
        </div>
    )
}
export default ContactForm
