import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions=props=>{

    const data=[
        {
            heading:``,
            para:`This site uses cookies from Google and other advertising platforms to deliver their services, to personalize ads and to analyze traffic. Information about your use of this site is shared with Google and other advertising platforms. By using this site, you agree to their use of cookies.`
        },
        {
            heading:`Please Read Carefully Cookies Policy of Tawheed Travel while booking from this site www.tawheedtravel.co.uk`,
            para:``
        },
        {
            heading:``,
            para:`When visiting our site Tawheed Travel and use our services and information, then you automatically obtain one or more cookies. To guarantee the finest delivery of our services, therefore, this is a principal purpose for using the cookies. We are here to abridge the all essential knowledge related to the cookies. You can also acquire about how you can accomplish on your own these cookies. Third party cookies from advertisers (google, bing, yahoo, facebook, twitter, youtube etc.) also used to analyze the user performance which implies according to their cookies policies.`
        },
        {
            heading:`What is Cookie?`,
            para:`When the user accesses certain websites, a small file which contains letters and numeric values and downloaded on your machine is called a cookie. Cookies permit a website to identify a user’s web browser, therefore, they are useful.`
        },
        {
            heading:`Why Cookies are useful?`,
            para:`Tawheed Travel use cookies because we want to improve the website functionality and cookies increase the website speed as well. These improvements are increased by the website content and depend on your website likes and dislikes which is given by tracking your data information. Using cookies, website response looks efficiently or professionally. 
            Based on your likes using cookies, we can find your desired destination too and take it easy, because we can’t find your personal data using cookies.`
        },
        {
            heading:``,
            para:`Analytical Cookies: It is allowed to see and analyze the user movement on our website Tawheed Travel and their interest permit us to increase the performance of services.`
        },
        {
            heading:``,
            para:`Technical Cookies: This type of cookies helps us, to maintain the flow of the website in a proper way.`
        },
        {
            heading:``,
            para:`Functional Cookies: When users requested our services then this cookie is important for us.`
        },
        {
            heading:``,
            para:`Social Cookies: These cookies are related to those platforms where our website promotes which is social networking sites such as Google plus, Twitter, Facebook, Pinterest, Flicker, Reddit and many more. We can handle social widgets through this cookie.`
        },
        {
            heading:``,
            para:`Identifying Cookies: Identifying cookies help us to analyze our visitors on the website. It is done by matching the visitor’s info with their personal data available in our files and ensure that relevant and personal communications are being sent to them.`
        },
        {
            heading:``,
            para:`Affiliated Cookies: Affiliated cookies allow us to track the visits from other websites that have agreements with us.`
        },
        {
            heading:`Methods to Control Cookies`,
            para:`There are some ways. Which we can use to manage these cookies are given below,`
        },
        {
            heading:``,
            para:`From storage cookies on your machine, block the specific sites
            Cookies which are existing in the browser just delete it
            Just block the 3rd party cookies
            Go your setting and disable cookies 
            To know more about managing your cookies on major browsers, you may visit the links mentioned below:`
        },
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-6">
                         <p className=' text-2xl tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        <p className=' text-md mb-3 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	
</div>
    )
}
export default TermandConditions